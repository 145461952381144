.App {
  width: 100vw;
  height: 100vh;
  background: url('./assets/auth-background.png');
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  background-attachment: fixed;
  background-clip: border-box;
  overflow: scroll;
}

@media (max-width: 600px) {
  .App {
    background: none;
  }
}