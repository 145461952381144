body {
  margin: 0;
  padding: 0;
  background-color: #f0f4f8;
}

@media (max-width: 600px) {
  body {
    background-color: #FFF;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}